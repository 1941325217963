export default [
  "undecided",
  "daily",
  "weekly",
  "monthly",
  "quarterly",
  "halfyearly",
  "yearly",
  "void",
  "monday-1",
  "tuesday-1",
  "wednesday-1",
  "thursday-1",
  "friday-1",
  "monday-2",
  "tuesday-2",
  "wednesday-2",
  "thursday-2",
  "friday-2"
]
